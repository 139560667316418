@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.act-codetest-modal {
    .ant-modal-content {
        border-radius: 4px;
        padding: 30px 0;
        height: 100vh;
    }

    .ant-modal-footer {
        display: none;
    }

    .ant-form {

        .ant-picker,
        .ant-input-number,
        .ant-input-number-affix-wrapper,
        .ant-select-selector,
        .ant-input-affix-wrapper {
            border-radius: 2px !important;
        }

        .ant-form-item-explain-error {
            line-height: 20px;
        }

        .ant-form-item {
            margin-bottom: 20px;
        }

        .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            display: inline-flex;
        }
    }

    .close {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .title-top {
        font-size: 18px;
        font-weight: 600;
        text-align: CENTER;
        color: #333333;
        line-height: 18px;
        margin-top: 0;
        margin-bottom: 30px;
    }

    .content {
        height: calc(100vh - 130px - 102px);
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .preview {
            width: 100%;
            height: 100%;
            padding: 40px 30px;
            overflow-y: auto;
            border: 1px solid #e5e5e5;
            border-radius: 2px;
            position: relative;

            &::-webkit-scrollbar {
                display: none;
            }

            &-container {
                padding: 0 50px;
                width: 100%;
                height: 100%;
            }

            &-title {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 12px;
                font-weight: 400;
                text-align: center;
                color: #1677ff;
                line-height: 26px;
                height: 28px;
                border: 1px solid rgba(22, 119, 255, 0.50);
                border-radius: 2px 0px 16px 0px;
                padding: 0 10px;
                width: fit-content;
            }

            &-content {
                width: 100%;

                &-title {
                    width: fit-content;
                    position: relative;
                    font-size: 18px;
                    font-weight: 600;
                    text-align: LEFT;
                    color: #333333;
                    line-height: 18px;
                    margin-top: 0;
                    margin-bottom: 16px;

                    &::before {
                        position: absolute;
                        content: '';
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 4px;
                        opacity: 0.3;
                        background: #1677ff;
                    }
                }

                &-topic {
                    font-size: 15px;
                    font-weight: 400;
                    text-align: LEFT;
                    color: #333333;
                    line-height: 23px;
                }
            }
        }

        .content1,
        .content0 {
            padding: 0 50px;

            .ant-form-item-label {
                min-width: 80px !important;
                text-align: right;
            }

            .item-tip {
                height: 32px;
                display: inline-flex;
                align-items: center;
                margin-left: 10px;

                span {
                    margin-left: 2px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 12px;
                }
            }

            .code-container {
                display: flex;
                flex-direction: column;

                &-nav {
                    background-color: #292929;
                    border-bottom: 1px solid #999999;
                    color: #cccccc;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    gap: 10px;
                    padding: 6px 10px;
                    font-weight: bold;

                    >span {
                        cursor: pointer;
                        padding: 0 6px;

                        &.light {
                            color: white;
                            background-color: #4d4d4d;
                            border-radius: 4px;
                        }
                    }
                }

                &-editor {
                    flex: 1;
                }
            }
        }

        .content2 {
            padding-left: 50px;

            .ant-form-item-label {
                min-width: 80px;
                text-align: right;
            }

            .ant-input-number-group-addon {
                background-color: transparent;
                border-radius: 0 2px 2px 0;
            }

            .ant-input-number {
                border-radius: 2px 0 0 2px !important;
            }

            .list-tip {
                height: 32px;
                display: inline-flex;
                align-items: center;

                span {
                    margin-left: 2px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 12px;
                }
            }

            .list-add {
                display: flex;
                width: fit-content;
                align-items: center;
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;

                span {
                    margin-left: 4px;
                }

                &[data-disabled=true] {
                    color: #CCCCCC;
                    cursor: default;

                    svg {
                        fill: #CCCCCC;
                        cursor: default;
                    }
                }

                &[data-disabled=false] {
                    color: #1677ff;
                    cursor: pointer;

                    svg {
                        fill: #1677ff;
                        cursor: pointer;
                    }
                }
            }

            .list-item {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .ant-form-item-label {
                    min-width: 20px;
                    text-align: right;
                }
            }

            .list-item-left {
                width: calc(100% - 50px);
                background: #f5f6fa;
                border-radius: 2px;
                padding: 24px 20px 4px;
            }

            .list-item-remove {
                margin-left: 10px;

                circle {
                    stroke: var(--color);
                }

                rect {
                    fill: var(--color);
                }

                &[data-disabled=false] {
                    --color: #999999;

                    &:hover {
                        cursor: pointer;
                        --color: #1677ff;
                    }

                }

                &[data-disabled=true] {
                    --color: #cccccc;
                }
            }

        }

        .content3 {
            padding: 0 50px;
            width: 100%;
            height: calc(100vh - 254px);
        }

        .content0 {
            &-verifymode {
                display: flex;
                align-items: center;

                >img {
                    width: 16px;
                    height: 16px;
                }

                &-tip {
                    margin-left: 2px;
                    font-size: 12px;
                    color: #999999;
                    line-height: 12px;
                }
            }
        }
    }
}